/** BREAKPOINT SETTINGS */
// Old bootstrap-based breaks:
// $breakpoint-xs: 0;
// $breakpoint-sm: 576px;
// $breakpoint-md: 768px;
// $breakpoint-lg: 992px;
// $breakpoint-xl: 1200px;

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1600px,
);

$container-max-widths: (
  sm: 560px,
  md: 860px,
  lg: 1100px,
  xl: 1400px,
);

// alternative breakpoints:
$breakpoint-xs: 0;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;

// TODO/IMPORTANT: UPDATE THE `CustomTheme.tsx` HARDCODED COLORS
$color-primary: #1a237e;
$color-secondary: #607d8b;
$color-info: #64b5f6;
$color-success: #009600; // #66bb6a;
$color-error: #f50057cc;
$color-warning: #ef6c00;
$color-background: #d0d0d0;
$color-background-light: #dddddddd;
$text-color-primary: #3a3a3a;
$text-color-secondary: #414141cc; // #555555
$text-color-disabled: #9a9a9add;

// shorthand variables
$text-color: $text-color-primary;

$variables: (
  // main ui colors
  --color-primary: $color-primary,
  --color-secondary: $color-secondary,
  --color-success: $color-success,
  --color-info: $color-info,
  --color-error: $color-error,
  --color-warning: $color-warning,
  // bg colors
  --color-background: $color-background,
  --color-background-light: $color-background-light,
  // text color
  --text-color-primary: $text-color-primary,
  --text-color-secondary: $text-color-secondary,
  --text-color-disabled: $text-color-disabled,
  ///// RGB triplets for dynamic rgba use //////
  --color-primary-rgb: #{hex-to-rgb-tuple($color-primary)},
  --color-secondary-rgb: #{hex-to-rgb-tuple($color-secondary)},
  --color-success-rgb: #{hex-to-rgb-tuple($color-success)},
  --color-info-rgb: #{hex-to-rgb-tuple($color-info)},
  --color-error-rgb: #{hex-to-rgb-tuple($color-error)},
  --color-warning-rgb: #{hex-to-rgb-tuple($color-warning)},
  --color-background-rgb: #{hex-to-rgb-tuple($color-background)},
  --color-background-light-rgb: #{hex-to-rgb-tuple($color-background-light)},
  --text-color-primary-rgb: #{hex-to-rgb-tuple($text-color-primary)},
  --text-color-secondary-rgb: #{hex-to-rgb-tuple($text-color-secondary)},
  --text-color-disabled-rgb: #{hex-to-rgb-tuple($text-color-disabled)},
  // breakpoints
  --breakpoint-xs: $breakpoint-xs,
  --breakpoint-sm: $breakpoint-sm,
  --breakpoint-md: $breakpoint-md,
  --breakpoint-lg: $breakpoint-lg,
  --breakpoint-xl: $breakpoint-xl
);

// Convert a hex value to comma-delimited rgb values
// In: #000
// Out: 0,0,0
@function hex-to-rgb-tuple($hex) {
  @return red($hex), green($hex), blue($hex);
}

%three-column-stacking-layout {
  display: grid;
  // grid-template-columns: minmax(160px, 33%) 1fr 1fr;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  grid-template-rows: minmax(140px, 150px) minmax(30vh, 1fr);
  gap: 1rem;
  // 63 + 80 + 40 + 43.6
  justify-items: stretch;
  justify-content: stretch;
  align-content: stretch;
  @media (max-width: $breakpoint-sm) {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(210px, 40vh) 140px minmax(200px, 1fr);
  }

  // grid-auto-rows: minmax(100px, auto);
  // grid-auto-flow: dense;
}

.home-layout {
  @extend %three-column-stacking-layout;
  // max-height: 80vh;
  // overflow-y: auto;
  // padding: 4px;
  // @media (max-height: 600) {
  //   max-height: 80vh;
  // }
  // @media (max-height: 800) {
  //   max-height: 90vh;
  // }
  .MuiPaper-root {
    // overflow-y: auto;
  }
}

.data-input-editor {
  grid-column: 1;
  grid-row: 1 / 4;
  @media (max-width: $breakpoint-sm) {
    grid-row: 1;
  }
  justify-self: stretch;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  > div {
    width: 100%;
  }
}
.output-buttons-panel {
  max-height: 150px;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1 span;

  @media (max-width: $breakpoint-sm) {
    grid-row: 2;
    grid-column: 1;
  }

  svg {
    max-height: 45px;
    min-width: 30px;
  }
  display: flex;
  justify-content: center;
  align-items: stretch;
  > div,
  .panel-body {
    width: 100%;
  }
  .output-buttons {
    justify-content: center;

    width: 100%;
    margin: 0.2rem auto;
  }
}
.code-viewer {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2 span;
  @media (max-width: $breakpoint-sm) {
    grid-row: 3;
    grid-column: 1;
  }
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  > div {
    width: 100%;
  }
}

.panel-component {
  min-width: 0;
  min-height: 0;
}
.panel-header {
  display: grid;
  grid-template-columns: 40% 60%; //repeat(auto-fill, minmax(40%, 1fr));
  justify-content: space-between;
  white-space: nowrap;
  margin: -16px;
  margin-bottom: 4px;
  padding: 16px;
  padding-bottom: 0;
  > p {
    text-align: right;
  }
  @media (max-width: $breakpoint-sm) {
    font-size: 1.125rem;
  }
}

.cursor-error {
  cursor: help;
}
.panel-error {
  cursor: help;
  svg {
    opacity: 0.5;
    color: #999;
  }
}

// Credit: https://www.florin-pop.com/blog/2019/03/css-pulse-effect/
// More reading: https://css-tricks.com/almanac/properties/a/animation/
.pulse-error-icon {
  animation: pulse-red 2s infinite;
  color: rgba(245, 0, 87, 1);
  box-shadow: 0 0 0 0 rgba(245, 0, 87, 1);
  border-radius: 100%;
  transform: scale(0.65);
  z-index: 100;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.65);
    box-shadow: 0 0 0 0 rgba(245, 0, 87, 0.7);
  }

  70% {
    transform: scale(0.9);
    box-shadow: 0 0 0 10px rgba(245, 0, 87, 0);
  }

  100% {
    transform: scale(0.65);
    box-shadow: 0 0 0 0 rgba(245, 0, 87, 0);
  }
}

// .two-column {
//   height: auto;
//   display: grid;
//   grid-gap: 1rem;
//   grid-template-columns: repeat(2, 1fr);

//   > .panel {
//     display: flex;
//     flex-direction: column;
//     padding: 1rem;
//     justify-content: space-between;
//   }
// }
