/** BREAKPOINT SETTINGS */
// Old bootstrap-based breaks:
// $breakpoint-xs: 0;
// $breakpoint-sm: 576px;
// $breakpoint-md: 768px;
// $breakpoint-lg: 992px;
// $breakpoint-xl: 1200px;

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1600px,
);

$container-max-widths: (
  sm: 560px,
  md: 860px,
  lg: 1100px,
  xl: 1400px,
);

// alternative breakpoints:
$breakpoint-xs: 0;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;

// TODO/IMPORTANT: UPDATE THE `CustomTheme.tsx` HARDCODED COLORS
$color-primary: #1a237e;
$color-secondary: #607d8b;
$color-info: #64b5f6;
$color-success: #009600; // #66bb6a;
$color-error: #f50057cc;
$color-warning: #ef6c00;
$color-background: #d0d0d0;
$color-background-light: #dddddddd;
$text-color-primary: #3a3a3a;
$text-color-secondary: #414141cc; // #555555
$text-color-disabled: #9a9a9add;

// shorthand variables
$text-color: $text-color-primary;

$variables: (
  // main ui colors
  --color-primary: $color-primary,
  --color-secondary: $color-secondary,
  --color-success: $color-success,
  --color-info: $color-info,
  --color-error: $color-error,
  --color-warning: $color-warning,
  // bg colors
  --color-background: $color-background,
  --color-background-light: $color-background-light,
  // text color
  --text-color-primary: $text-color-primary,
  --text-color-secondary: $text-color-secondary,
  --text-color-disabled: $text-color-disabled,
  ///// RGB triplets for dynamic rgba use //////
  --color-primary-rgb: #{hex-to-rgb-tuple($color-primary)},
  --color-secondary-rgb: #{hex-to-rgb-tuple($color-secondary)},
  --color-success-rgb: #{hex-to-rgb-tuple($color-success)},
  --color-info-rgb: #{hex-to-rgb-tuple($color-info)},
  --color-error-rgb: #{hex-to-rgb-tuple($color-error)},
  --color-warning-rgb: #{hex-to-rgb-tuple($color-warning)},
  --color-background-rgb: #{hex-to-rgb-tuple($color-background)},
  --color-background-light-rgb: #{hex-to-rgb-tuple($color-background-light)},
  --text-color-primary-rgb: #{hex-to-rgb-tuple($text-color-primary)},
  --text-color-secondary-rgb: #{hex-to-rgb-tuple($text-color-secondary)},
  --text-color-disabled-rgb: #{hex-to-rgb-tuple($text-color-disabled)},
  // breakpoints
  --breakpoint-xs: $breakpoint-xs,
  --breakpoint-sm: $breakpoint-sm,
  --breakpoint-md: $breakpoint-md,
  --breakpoint-lg: $breakpoint-lg,
  --breakpoint-xl: $breakpoint-xl
);

// Convert a hex value to comma-delimited rgb values
// In: #000
// Out: 0,0,0
@function hex-to-rgb-tuple($hex) {
  @return red($hex), green($hex), blue($hex);
}

h1 {
  min-height: 50px;
}
.brand-link {
  --logo-primary: var(--color-primary);
  --logo-secondary: var(--color-success);
  &:hover {
    --logo-primary: var(--color-primary);
    --logo-secondary: var(--color-error);
    color: var(--logo-primary);
  }

  line-height: 1.1;
  font-family: Lato, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  cursor: pointer;
  margin: 0.1rem 1rem;
  font-size: 22px;
  display: flex;
  align-items: flex-end;
  // @media (max-width: $breakpoint-sm) {
  //   span {
  //     display: none;
  //   }
  // }
  svg {
    // margin-top: -20px;
    // margin-left: -10px;
    // margin-right: 10px;
    margin: 0 0.4rem 0 0;
    // margin: 0.2rem 0.6rem 0.2rem 0.2rem;
    width: 55px;
    height: 55px;
  }
}

.current-xs,
.current-sm {
  .brand-link {
    font-size: 1.4rem;
  }
}

.icon-button-box {
  // transform: translateX(31px) translateY(-16px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  > a {
    &:hover {
      svg {
        color: var(--color-success);
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
      }
    }
  }
  > a,
  > label {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    width: 42px;
    height: 36px;
    text-align: center;
  }
  > label {
    width: auto;
  }
  > .expand-spacer {
    flex-grow: 4;
  }
}

.nav-breadcrumbs {
  margin: 0 1rem !important;
  a:hover,
  a.active {
    span {
      text-decoration: underline;
    }
  }
}

.demo-link {
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // margin: 0.5rem auto;

  transition: all 0.5s ease-in;
  cursor: pointer;
  // text-decoration: underline;
  &:hover {
    color: var(--text-color-secondary);
    .roll-dice {
      animation: roll-dice 1.5s steps(6) infinite;
      background-color: var(--color-error);
    }
  }
}

.options-link {
  transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
  svg {
    transition: color 550ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  &.active {
    transform: rotate(1turn);
    // svg {
    // }
  }
  &:hover {
    transform: rotate(-180deg);
    svg {
      color: var(--color-success);
    }
  }
}

.roll-dice-wrapper {
  // border: 1px solid var(--text-color-secondary);
  border-radius: 0.3rem;
  width: 28px;
  height: 28px;
  padding: 1px 1px 0 0;
}
.roll-dice {
  box-sizing: border-box;
  background-color: var(--color-primary);
  &.animated {
    animation: roll-dice 0.75s steps(6) infinite;
  }
  border-radius: 0.5rem;
  display: inline-block;
  // animation: roll-dice 1.5s steps(6) infinite;
  background-image: url(../../../public/images/dice-sprite.svg);
  width: 25px;
  height: 25px;
  background-size: cover;
}

@keyframes roll-dice {
  100% {
    background-position: -600px 0;
  }
}

.roll-dice-20px-wrapper {
  // border: 1px solid var(--text-color-secondary);
  border-radius: 0.3rem;
  width: 24px;
  height: 24px;
  padding: 1px 1px 0 0;
}
.roll-dice-20px {
  box-sizing: border-box;
  background-color: var(--color-primary);
  &.animated {
    animation: roll-dice-20px 0.75s steps(6) infinite;
  }
  border-radius: 0.5rem;
  display: inline-block;
  // animation: roll-dice-20px 1.5s steps(6) infinite;
  background-image: url(../../../public/images/dice-sprite-h20px.svg);
  width: 20px;
  height: 20px;
  background-size: cover;
}

@keyframes roll-dice-20px {
  100% {
    background-position: -120px 0;
  }
}

///////////////
.btn-load-demo {
  &:hover {
    .roll-dice-16px {
      animation: roll-dice-16px 1.25s steps(6) infinite;
    }
  }
}
.roll-dice-16px-wrapper {
  margin-top: 2px;
  line-height: 1;
  // border: 1px solid var(--text-color-secondary);
  // border-radius: 2px;
  // width: 19px;
  // height: 19px;
  padding: 0; //1px 1px 0 0;
}
.roll-dice-16px {
  box-sizing: border-box;
  background-color: var(--color-primary);
  &.animated {
    animation: roll-dice-16px 0.75s steps(6) infinite;
  }
  border-radius: 2px;
  display: inline-block;
  // animation: roll-dice-16px 1.5s steps(6) infinite;
  background-image: url(../../../public/images/dice-sprite-h16px.svg);
  width: 16px;
  height: 16px;
  background-size: cover;
}

@keyframes roll-dice-16px {
  100% {
    background-position: -96px 0;
  }
}
