/** BREAKPOINT SETTINGS */
// Old bootstrap-based breaks:
// $breakpoint-xs: 0;
// $breakpoint-sm: 576px;
// $breakpoint-md: 768px;
// $breakpoint-lg: 992px;
// $breakpoint-xl: 1200px;

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1600px,
);

$container-max-widths: (
  sm: 560px,
  md: 860px,
  lg: 1100px,
  xl: 1400px,
);

// alternative breakpoints:
$breakpoint-xs: 0;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;

// TODO/IMPORTANT: UPDATE THE `CustomTheme.tsx` HARDCODED COLORS
$color-primary: #1a237e;
$color-secondary: #607d8b;
$color-info: #64b5f6;
$color-success: #009600; // #66bb6a;
$color-error: #f50057cc;
$color-warning: #ef6c00;
$color-background: #d0d0d0;
$color-background-light: #dddddddd;
$text-color-primary: #3a3a3a;
$text-color-secondary: #414141cc; // #555555
$text-color-disabled: #9a9a9add;

// shorthand variables
$text-color: $text-color-primary;

$variables: (
  // main ui colors
  --color-primary: $color-primary,
  --color-secondary: $color-secondary,
  --color-success: $color-success,
  --color-info: $color-info,
  --color-error: $color-error,
  --color-warning: $color-warning,
  // bg colors
  --color-background: $color-background,
  --color-background-light: $color-background-light,
  // text color
  --text-color-primary: $text-color-primary,
  --text-color-secondary: $text-color-secondary,
  --text-color-disabled: $text-color-disabled,
  ///// RGB triplets for dynamic rgba use //////
  --color-primary-rgb: #{hex-to-rgb-tuple($color-primary)},
  --color-secondary-rgb: #{hex-to-rgb-tuple($color-secondary)},
  --color-success-rgb: #{hex-to-rgb-tuple($color-success)},
  --color-info-rgb: #{hex-to-rgb-tuple($color-info)},
  --color-error-rgb: #{hex-to-rgb-tuple($color-error)},
  --color-warning-rgb: #{hex-to-rgb-tuple($color-warning)},
  --color-background-rgb: #{hex-to-rgb-tuple($color-background)},
  --color-background-light-rgb: #{hex-to-rgb-tuple($color-background-light)},
  --text-color-primary-rgb: #{hex-to-rgb-tuple($text-color-primary)},
  --text-color-secondary-rgb: #{hex-to-rgb-tuple($text-color-secondary)},
  --text-color-disabled-rgb: #{hex-to-rgb-tuple($text-color-disabled)},
  // breakpoints
  --breakpoint-xs: $breakpoint-xs,
  --breakpoint-sm: $breakpoint-sm,
  --breakpoint-md: $breakpoint-md,
  --breakpoint-lg: $breakpoint-lg,
  --breakpoint-xl: $breakpoint-xl
);

// Convert a hex value to comma-delimited rgb values
// In: #000
// Out: 0,0,0
@function hex-to-rgb-tuple($hex) {
  @return red($hex), green($hex), blue($hex);
}

.code-toolbar {
  position: absolute;
  right: 0.8rem;
  top: -0.2rem;
  z-index: 10000;
  &.MuiButtonGroup-root,
  button {
    // max-height: 44.25px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  // .btn-height-hack {
  //   height: 44px;
  // }
  // &.MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
  //   height: 44px;
  // }
}
